import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-pay-now-failed',
  templateUrl: './pay-now-failed.component.html',
  styleUrls: ['./pay-now-failed.component.css']
})
export class PayNowFailedComponent {
  orgid: any;
  showCampaignButton = false;
  whitelableURL = "";
  constructor(private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe(
      params => {
       
        this.orgid = params['organizationid'];
        if (this.orgid?.trim()) {
          this.showCampaignButton = true;
          this.setWhitelableURL();
        }
      }
    );   

  }
  setWhitelableURL() {
    let URL = environment.whitelable_domain_url;
    URL += "/home?organizationid=" + this.orgid;
    this.whitelableURL = URL;
  }
}
