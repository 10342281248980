import { ActivatedRoute } from '@angular/router';
import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pay-now-success',
  templateUrl: './pay-now-success.component.html',
  styleUrls: ['./pay-now-success.component.css']
})
export class PayNowSuccessComponent {
  whitelabel: any;
  orgid: any;
  campaignid: any;
  whitelable_domain_url = environment.whitelable_domain_url;
  versions = [
    {
      width: '44px',
      height: '10px',
      color: '#548235',
      marginBottom: '15px',
      marginLeft: '10px',
    }
  ];
  emailId: any;
  actionId: any;
  qrcode_Id: any;
  showCampaignButton = false;
  whitelableURL = "";

  constructor(private activatedRoute: ActivatedRoute) {
    this.activatedRoute.params.subscribe(
      params => {
        // this.whitelabel = params['whitelabel'];
        this.orgid = params['organizationid'];
        this.campaignid = params['campaignid'];
        this.emailId = params['emailid'];
        this.actionId = params['actionid'];
        this.qrcode_Id = params['qrcode_id'];
        if (this.orgid?.trim()) {
          this.showCampaignButton = true;
          this.setWhitelableURL();
        }
      }
    );
  }
  setWhitelableURL() {
    let URL = environment.whitelable_domain_url;
    URL += "/home?organizationid=" + this.orgid;
    this.whitelableURL = URL;
  }
}
