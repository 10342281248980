<!--<div class="intake-page-wrapper h-100 py-4 px-3" [ngStyle]="getBackgroundColor()">-->
<div class="intake-page-wrapper h-100 py-4 px-3" [ngStyle]="{
    'background': 'linear-gradient(180deg, ' + campaignIntakePageSettingsData?.background_color + ' 50%, #f3f3f3 50%)' 
}">

  <div class="container mx-lg-auto mx-md-auto mx-sm-auto mx-3" style="font-family: 'Arial', sans-serif;max-width: 600px;background: white;padding: 0; ">
    <ngx-spinner type="ball-scale-multiple"></ngx-spinner>
    <form id="paymentForm" [formGroup]="paymentForm" style="border: 1px solid #f3f3f3; " *ngIf="campaignIntakePageSettingsData">
      <div class="progress" style="height: 8px;border-radius: 0;background: #ffffff;">
        <div class="progress-bar" role="progressbar" id="progressBar" style="width: 33.33%; background-color: #FF9800; border-radius: 0px 10px 10px 0px;" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
      <div class="name-logo-n-title d-flex w-100 px-2" style="flex-wrap: wrap; align-items: center; background: #f3f3f3;">
        <h2 class="text-start ms-2" style="font-size: clamp(0.875rem, 0.4086rem + 1.4925vw, 1.125rem); text-align: center; margin: 10px 0;">
          <span>{{organizationName}}</span><br />
          <ng-container *ngIf="campaignName?.trim()">
            <span style="font-size:12px;">{{campaignName}}</span>
          </ng-container>

        </h2>
        <i (click)="onLogout()" *ngIf="paymentForm.value.payer_account_id?.trim()" class="fas fa-power-off ms-3 ps-3 " type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Log-out" style="color: #000; border-left: 1px solid #c4c4c4;"></i>

        <img *ngIf="campaignIntakePageSettingsData?.organization_logo_url?.trim()" [src]="campaignIntakePageSettingsData?.organization_logo_url?.trim()" alt="brand-logo" style="min-width: 50px;max-width: 50px;background: #ffffff;max-height: 50px;object-fit: cover;object-position: center;border: 1px solid #efefef;border-radius: 8px;min-height: 50px;padding: 2px;margin-left: auto;" class="w-100 h-100" />
      </div>
      <!-- Step 1: Enter Amount to Pay -->
      <div class="form-step" id="step1" style="display: block;">
        <div class="step-header flex-lg-nowrap flex-md-nowrap flex-sm-wrap flex-wrap"
             style="display: flex; justify-content: space-between; align-items: center;  padding: 10px 20px;"
             [ngStyle]="getHeaderStyles()">
          <div class="name-n-backBtn d-flex align-items-center" style="flex-grow: 1;">
            <div style="cursor: pointer; color: #2b2b2b;"></div>
            <h2 class="text-start ms-2" style="font-size: clamp(0.875rem, 0.4086rem + 1.4925vw, 1.125rem); color: #ffffff; text-align: center; margin: 10px 0;">Choose Amount</h2>

          </div>
          <div class="step-number " style="font-size: 14px; color: #ffffff; flex-grow: 1;">Step 1 of 3</div>
        </div>
        <div class="rest-field-form p-lg-4 p-md-4 p-sm-2 p-2">
          <img *ngIf="campaignIntakePageSettingsData?.display_image && QR_info_data?.campaign_url" [src]="QR_info_data?.campaign_url" style=" width: 100%; max-height: 200px; height: 100%; object-fit: cover; border-radius: 10px; margin-bottom: 10px;">
          <label *ngIf="campaignIntakePageSettingsData?.call_to_action?.trim()"
                 style="color: #2b2b2b; font-size: 14px; margin-bottom: 0px;">
            {{campaignIntakePageSettingsData?.call_to_action?.trim()}}
          </label><br>

          <ng-container *ngIf="campaignIntakePageSettingsData?.recurring_frequencies && campaignIntakePageSettingsData?.recurring_frequencies?.length > 0">





            <label style="color: #2b2b2b; font-size: 13px; font-style: italic;">You can log in to edit your recurring donation any time.. <span class="tooltipInfombtn position-relative"><span class="buttonTooltips">Information</span><i class="far fa-question-circle" type="button"></i></span></label>
            <div class="d-flex my-3 field-pay-timming d-flex justify-content-center flex-wrap">
              <ng-container *ngFor="let recurring of campaignIntakePageSettingsData?.recurring_frequencies; let i=index">
                <label class="custom-control custom-radio me-lg-2 me-sm-2 me-0 mb-lg-0 mb-md-0 mb-sm-2 mb-2 position-relative custom-freq-field "
                       [ngStyle]="getFrencyLabelStyles(recurring)"
                       style="padding: 10px 20px; text-align: center;">
                  <input type="radio" class="custom-control-input" formControlName="recurring_frequency" [value]="recurring">
                  <i class="fas fa-star me-1" *ngIf="campaignIntakePageSettingsData?.recommended_frequency == recurring"></i> <span class="custom-control-label">{{recurringFrequencyList[recurring]}}</span>
                </label>
              </ng-container>
              <div *ngIf="paymentForm.controls['recurring_frequency'].invalid &&
                           (paymentForm.controls['recurring_frequency'].touched || paymentForm.controls['recurring_frequency'].dirty )">
                <small class="text-danger" *ngIf="paymentForm.controls['recurring_frequency'].errors['required']">
                  Please select donation interval
                </small>
              </div>
            </div>
          </ng-container>

          <!-- Amount Input -->
          <ng-container *ngIf="campaignIntakePageSettingsData?.predefined_amounts?.length > 0">
            <hr class="divider">
            <label style="color: #2b2b2b; font-size: 14px;">Donation Amount:</label>
            <div class="my-3 d-flex justify-content-start flex-wrap amoutSelectionBtn-box">

              <button *ngFor="let pred_amount of campaignIntakePageSettingsData?.predefined_amounts; let i=index"
                      type="button" (click)="setAmount(pred_amount.amount)"
                      class="btn amoutSelectionBtn tooltipInfombtn btn btn-outline-custom rounded-0 position-relative "
                      style="border-color: #2b2b2b; color: #2b2b2b;"
                      [ngStyle]="getPredifinedAmountStyles(pred_amount.amount)">
                <span class="buttonTooltips" *ngIf="pred_amount.description?.trim()">{{pred_amount.description}}</span>
                {{ pred_amount.amount | currency:'USD' }}
              </button>
            </div>

          </ng-container>
          <ng-container *ngIf="campaignIntakePageSettingsData?.allow_custom_donation">
            <hr class="divider">
            <label style="color: #2b2b2b; font-size: 14px; margin-bottom: 5px;">Enter Custom Amount:</label>
            <div class="pay-value-box">
              <input type="number" positivenumeric formControlName="amount" class="form-control" placeholder="Enter amount">
            </div>
            <div *ngIf="paymentForm.controls['amount'].invalid &&
                           (paymentForm.controls['amount'].touched || paymentForm.controls['amount'].dirty )">
              <small class="text-danger" *ngIf="paymentForm.controls['amount'].errors['required']">
                Amount is required
              </small>
              <small class="text-danger" *ngIf="paymentForm.controls['amount'].errors['min'] ">
                Amount should be {{paymentForm.controls['amount'].errors['min']['min'] | currency:'USD' }} or greater than
                {{paymentForm.controls['amount'].errors['min']['min'] | currency:'USD'}}
              </small>
            </div>
          </ng-container>
          <hr class="divider">
          <!-- Checkbox Section -->
          <div class="mt-3">
            <!-- IF Check is checked then this CSS-->
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="no_share_giving" formControlName="no_share_giving" name="no_share_giving"
                     [ngStyle]="getCheckboxStyles('no_share_giving')">
              <label class="form-check-label" style="font-size: 14px;" for="no_share_giving">
                Don't display my name publicly on the fundraiser
              </label>
            </div>
            <ng-container *ngIf="campaignIntakePageSettingsData?.payer_cover_fee">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="allow_payer_cover_amount" name="allow_payer_cover_amount" formControlName="allow_payer_cover_amount" [ngStyle]="getCheckboxStyles('allow_payer_cover_amount')">
                <label class="form-check-label" style="font-size: 14px;" for="allow_payer_cover_amount">
                  Increase my gift by ${{ (this.giftFeesAmt() | number: '1.2-2') }} to offset processing fees.
                </label>
              </div>
            </ng-container>
          </div>
          <button type="button" class="btn btn-custom mt-5" (click)="validateAmountStep()"
                  [ngStyle]="getButtonStyles()"
                  style=" border-bottom: 5px solid #17962a;  width: 100%; padding: 15px 10px; text-transform: uppercase; font-weight: 400;">
            Next
          </button>
        </div>
      </div>

      <!-- Step 2: Enter Payment Details -->
      <div class="form-step" id="step2" style="display: none;">
        <div class="step-header flex-lg-nowrap flex-md-nowrap flex-sm-wrap flex-wrap"
             [ngStyle]="getHeaderStyles()"
             style="display: flex; justify-content: space-between; align-items: center; padding: 10px 20px;">
          <div class="name-n-backBtn d-flex align-items-center" style="flex-grow: 1;">
            <div style="cursor: pointer;" (click)="changeStep(-1)"><i class="fas fa-arrow-left"></i></div>
            <h2 class="text-start ms-2" style="font-size: clamp(0.875rem, 0.4086rem + 1.4925vw, 1.125rem); color: #ffffff; text-align: center; margin: 10px 0;">Enter Information</h2>

          </div>
          <div class="step-number " style="font-size: 14px; color: #ffffff; flex-grow: 1;">Step 2 of 3</div>
        </div>
        <div class="rest-field-form p-lg-4 p-md-4 p-sm-2 p-2">
          <div class="not-login-user" *ngIf="isLoginClicked" [formGroup]="loginForm">
            <label class="login-title mb-3"><i class="fas fa-lock me-2"></i>Login Now</label>
            <div class="form-row row enter-login-form">
              <div class="form-group mb-3 col-lg-6 col-md-6 col-sm-6 col-12">
                <label for="userName" style="color: #2b2b2b; font-size: 13px;">Username:</label>
                <input type="text" formControlName="username" class="form-control" placeholder="Username">
                <span class="e-error"
                      *ngIf="(loginFormControls['username'].touched ||  loginFormControls['username'].dirty ) && loginFormControls['username'].errors">
                  Username is required
                </span>
              </div>
              <div class="form-group mb-3 col-lg-6 col-md-6 col-sm-6 col-12">
                <label for="userPassword" style="color: #2b2b2b; font-size: 13px;">Password:</label>
                <input type="password" class="form-control" placeholder="Password" formControlName="password">
                <span class="e-error"
                      *ngIf="(loginFormControls['password'].touched ||  loginFormControls['password'].dirty ) && loginFormControls['password'].errors">
                  Password is required
                </span>
              </div>
            </div>
          </div>
          <!--<hr class="divider" style=" margin-top: 18px !important; margin-bottom: 29px;" />-->
          <ng-container *ngIf="!isLoginClicked">

            <div formGroupName="card">
              <div class="form-row row mb-2">
                <p *ngIf="!paymentForm.value.payer_account_id?.trim()">Already have account? <a (click)="openLoginForm()" style="color: #502488;font-weight: 500;text-transform: uppercase; cursor:pointer;text-decoration:underline">Login</a></p>
              </div>
              <div class="form-row row">
                <div class="form-group mb-3 col-lg-6 col-md-6 col-sm-6 col-12">
                  <label for="fullName" style="color: #2b2b2b; font-size: 13px;">Name:</label>
                  <input type="text" class="form-control" placeholder="Name" formControlName="name_on_card">
                  <!--<ejs-textbox class="" id="name_on_card" name="name_on_card" placeholder="Name on Card"
                   floatLabelType="Never" aria-errormessage="Name is required" formControlName="name_on_card"
                  required></ejs-textbox>-->
                  <span class="e-error"
                        *ngIf="(cardFormControls['name_on_card'].touched ||  cardFormControls['name_on_card'].dirty ) && cardFormControls['name_on_card'].errors">
                    Name is required
                  </span>
                </div>
                <div class="form-group mb-3 col-lg-6 col-md-6 col-sm-6 col-12">
                  <label for="userEmail" style="color: #2b2b2b; font-size: 13px;">Email:</label>
                  <input type="text" id="email" formControlName="email" class="form-control" placeholder="Email">
                  <div *ngIf="cardFormControls['email'].invalid && (cardFormControls['email'].touched || cardFormControls['email'].dirty )">
                    <small class="e-error" *ngIf="cardFormControls['email'].errors['required']">
                      Email is required
                    </small>
                    <small class="e-error" *ngIf="cardFormControls['email'].errors['email'] || cardFormControls['email'].errors['pattern']">
                      Please enter valid email address
                    </small>
                  </div>
                </div>
              </div>
              <div class="form-group mb-3">
                <label for="userZipcode" style="color: #2b2b2b; font-size: 13px;">Zipcode:</label>
                <input type="text" id="userZipcode" formControlName="zip" class="form-control" placeholder="Zipcode">
                <span class="e-error" *ngIf="(cardFormControls.zip.touched || cardFormControls.zip.dirty) && cardFormControls.zip.errors?.pattern">
                  Please enter valid zip code
                </span>
                <ng-container *ngIf="!cardFormControls.zip.errors?.pattern">
                  <span class="e-error" *ngIf="(cardFormControls.zip.touched || cardFormControls.zip.dirty) && cardFormControls.zip.errors?.minlength">
                    Minimum 5 digits
                  </span>
                  <span class="e-error" *ngIf="(cardFormControls.zip.touched || cardFormControls.zip.dirty) && cardFormControls.zip.errors?.maxlength">
                    Maximum 11 digits
                  </span>
                </ng-container>

              </div>
            </div>
            <div class="form-check" *ngIf="!paymentForm.value.payer_account_id?.trim() && (paymentForm.value.recurring_frequency == 'onetime')">
              <input class="form-check-input" type="checkbox" id="create_account" formControlName="create_account" name="create_account"
                     [ngStyle]="getCheckboxStyles('create_account')">
              <label class="form-check-label" style="font-size: 14px;" for="create_account">
                I want to create an account to manage my donations.
              </label>
            </div>
            <div class="flex-buttons-display row" *ngIf="!isLoginClicked">
              <div class="mt-4">
                <button type="button" class="btn btn-custom  " (click)="validateInformationStep()"
                        [ngStyle]="getButtonStyles()"
                        style=" border-bottom: 5px solid #17962a; width:100%;  padding: 15px 10px; text-transform: uppercase; font-weight: 400;">
                  Next
                </button>
              </div>
            </div>
          </ng-container>


          <div class="flex-buttons-display row w-100 mx-auto mt-4" *ngIf="isLoginClicked">
            <div class="col-lg-6 col-md-6 col-sm-6 col-12 ps-0">
              <button type="button" class="btn btn-custom  " (click)="onLogin()"
                      [ngStyle]="getButtonStyles()"
                      style=" border-bottom: 5px solid #17962a; width:100%;  padding: 15px 10px; text-transform: uppercase; font-weight: 400;">
                Login
              </button>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6 col-12 d-block pe-0">
              <button type="button" class="btn btn-custom w-100 cancelledBtnForm " (click)="onLoginCancel()" style="border-bottom: 5px solid #bcbcbc;padding: 15px 10px;text-transform: uppercase;font-weight: 400;background: #e1e1e1;">
                Cancel
              </button>
            </div>
          </div>


        </div>
      </div>

      <!-- Step 3: Choose Payment Option -->
      <div class="form-step active" id="step3" style="display: none;">
        <div class="step-header flex-lg-nowrap flex-md-nowrap flex-sm-wrap flex-wrap"
             [ngStyle]="getHeaderStyles()"
             style="display: flex;justify-content: space-between;align-items: center;padding: 10px 20px;">
          <div class="name-n-backBtn d-flex align-items-center  flex-wrap" style="flex-grow: 1;">
            <div style="cursor: pointer;" (click)="changeStep(-1)"><i class="fas fa-arrow-left"></i></div>
            <h2 class="text-start ms-2" style="font-size: clamp(0.875rem, 0.4086rem + 1.4925vw, 1.125rem); color: #ffffff; text-align: center; margin: 10px 0;">Choose Payment Option</h2>

          </div>
          <div class="step-number  " style="font-size: 14px;color: #ffffff;flex-grow: 1;">Step 3 of 3</div>
        </div>
        <div class="rest-field-form p-lg-4 p-md-4 p-sm-2 p-2">
          <div class="form-group mb-3 d-flex flex-lg-nowrap flex-md-nowrap flex-sm-wrap flex-wrap mb-3" *ngIf="this.paymentForm.value.payer_account_id && this.paymentSourceData?.charge_cards && this.paymentSourceData?.charge_cards.length > 0">
            <label class="custom-control position-relative custom-radio me-lg-3 me-md-3 me-sm-3 me-3 d-flex align-items-center">
              <input type="radio" class="custom-control-input position-absolute" style="opacity: 0;" name="existing_payment_source" [value]="true" formControlName="existing_payment_source" (change)="selectExistingPaymentChange()">
              <span class="custom-control-label"
                    [ngStyle]="getPaymentSourceRadioButonStyles('existing_payment_source', 'existingPayment')"
                    style="font-size: 14px;cursor:pointer"> Select Existing Payment Source</span>
            </label>
            <label class="custom-control custom-radio position-relative d-flex align-items-center me-lg-3 me-md-3 me-sm-3 me-3 ">
              <input type="radio" class="custom-control-input position-absolute" style="opacity: 0;" name="existing_payment_source" [value]="false" formControlName="existing_payment_source" (change)="selectExistingPaymentChange()">
              <span class="custom-control-label"
                    [ngStyle]="getPaymentSourceRadioButonStyles('existing_payment_source', 'newPayment')"
                    style="font-size: 14px; cursor: pointer">Create New Payment Source</span>
            </label>
          </div>
          <!--Show below form if "Select Existing Payment Source" is selected radio button -->
          <div class="form-group mb-3" *ngIf="this.paymentForm.value.existing_payment_source && this.paymentSourceData?.charge_cards && this.paymentSourceData?.charge_cards.length > 0">
            <label for="paymentMethod" style="color: #2b2b2b; font-size: 13px;">Select Payment Method:</label>
            <select class="form-select " formControlName="payment_source_id"
                    [ngClass]="{'is-invalid': mainFormControls['payment_source_id'].touched && mainFormControls['payment_source_id'].errors, 'is-valid': mainFormControls['payment_source_id'].valid}">
              <option value="">Select Payment Source</option>
              <option *ngFor="let card of paymentSourceData?.charge_cards" [value]="card.card_id">
                {{card.brand_of_card}} - {{card.last_four}}
              </option>
            </select>
            <div *ngIf="mainFormControls['payment_source_id'].invalid &&
                           (mainFormControls['payment_source_id'].touched || mainFormControls['payment_source_id'].dirty )">
              <small class="text-danger" *ngIf="mainFormControls['payment_source_id'].errors['required']">
                Please select payment source
              </small>
            </div>
          </div>

          <!--Show below form if "Create New Payment Source" is selected radio button -->
          <ng-container *ngIf="!this.paymentForm.value.existing_payment_source">
            <div class="not-linked-account" formGroupName="card">
              <!-- If want to apply bg color on this title lable also add the color code as like "{{color}}0f" here '0f' is the aplha value of code..."-->
              <label style="display: block; background: #5024880f; padding: 10px 15px; margin-bottom: 15px;">Enter Card Details</label>
              <div class="form-group mb-3">
                <label for="cardNumber" style="color: #2b2b2b; font-size: 13px;">Card Number:</label>
                <!--<input type="text" id="cardNumber" name="cardNumber" class="form-control" placeholder="Eg: 1234 5678 9012 3456" required>-->
                <div id="card-number-element" style="border-radius: 0%;">
                </div>
                <div *ngIf="stripeCardNumberErrorMessage" class="">
                  <small class="text-danger">
                    {{stripeCardNumberErrorMessage}}
                  </small>
                </div>
              </div>
              <div class="form-row row">
                <div class="form-group mb-3 col-lg-6 col-md-6 col-sm-6 col-6">
                  <label for="expiryDate" style="color: #2b2b2b; font-size: 13px;">Expiry Date:</label>
                  <!--<input type="text" id="expiryDate" name="expiryDate" class="form-control" placeholder="MM/YY" required>-->
                  <div id="card-exp-element" style="border-radius: 0%;"></div>

                  <div *ngIf="stripeExpiryErrorMessage" class="">
                    <small class="text-danger">
                      {{stripeExpiryErrorMessage}}
                    </small>
                  </div>
                </div>
                <div class="form-group mb-3 col-lg-6 col-md-6 col-sm-6 col-6">
                  <label for="cvv" style="color: #2b2b2b; font-size: 13px;">CVV:</label>
                  <!--<input type="text" id="cvv" name="cvv" class="form-control" required>-->
                  <div id="card-cvc-element" style="border-radius: 0%;"></div>
                  <div *ngIf="stripeCVCErrorMessage" class="">
                    <small class="text-danger">
                      {{stripeCVCErrorMessage}}
                    </small>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-check mb-3" *ngIf="showFutureUseCheckbox()">
              <input class="form-check-input" type="checkbox" id="future_use" formControlName="future_use" name="future_use"
                     [ngStyle]="getCheckboxStyles('future_use')">
              <label class="form-check-label" style="font-size: 14px;" for="future_use">
                Save this payment method for future usage
              </label>
            </div>

          </ng-container>
          <div class="form-check mb-3">
            <input class="form-check-input" type="checkbox" id="email_optin" formControlName="email_optin" name="email_optin"
                   [ngStyle]="getCheckboxStyles('email_optin')">
            <label class="form-check-label" style="font-size: 14px;" for="email_optin">
              I want to receive communication from {{organizationName}}
            </label>
          </div>
          <!--<div class="form-row row mb-3">-->
          <div class="form-row row" *ngIf="campaignIntakePageSettingsData?.allow_tracking_code && campaignIntakePageSettingsData.tracking_code?.code_list?.length > 0">
            <div class="form-group  col-sm-12">
              <label for="expiryDate" style="color: #2b2b2b; font-size: 13px;">{{this.campaignIntakePageSettingsData.tracking_code.label?.trim()}}</label>
              <select class="form-select " formControlName="tracking_code">

                <option value="">Select Code</option>

                <option *ngFor="let item of campaignIntakePageSettingsData.tracking_code?.code_list">{{item}}</option>
              </select>
            </div>

          </div>
          <button type="button" class="btn btn-custom mt-4 d-flex flex-wrap justify-content-center align-items-center" (click)="onSubmit()"
                  [ngStyle]="getButtonStyles()"
                  style="border-bottom: 5px solid #17962a;  color: #fff;width: 100%;padding: 15px 10px;text-transform: uppercase;font-weight: 400;">
            {{campaignIntakePageSettingsData?.button_label}}
            <span class="mx-1" style="font-weight: 600; font-size: 22px;">
              ${{this.calculateTotalAmt() | number: '1.2-2'}}
            </span> {{ recurringFrequencyList[paymentForm.value.recurring_frequency]}}
          </button>
        </div>
      </div>

      <p class="poweredby" style="display: flex;flex-wrap: wrap;align-items: center;margin-top: 0;justify-content: center;"><span style="font-size: 12px;">Powered By |</span><img src="https://stage.gogiveanywhere.com/assets/images/new-ui-image/logo-2.png" style="max-width: 69px;margin-left: 4px;"></p>
    </form>


  </div>
</div>
