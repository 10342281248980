import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { GiveNowService } from '../../_services/give-now.service';
import { loadStripe, Stripe, StripeElements, StripeCardNumberElementOptions, StripeCardNumberElement, StripeCardExpiryElement, StripeCardCvcElement, StripeCardExpiryElementOptions, StripeCardCvcElementOptions, ConfirmCardSetupData } from '@stripe/stripe-js';
import { environment } from '../../../environments/environment';
import { ToastMessage } from '../../common/commonfunction';
import { timer } from 'rxjs';

@Component({
  selector: 'app-pay-now-customize',
  templateUrl: './pay-now-customize.component.html',
  styleUrls: ['./pay-now-customize.component.css']
})
export class PayNowCustomizeComponent implements OnInit, OnDestroy, AfterViewInit {
  campaignIntakePageSettingsData;
  paymentForm: FormGroup;
  submitted: boolean = false;
  calculatedTipAmount: number = 0;
  donationAmount: number;
  fatchedParams: any;
  organizationName: string;
  sourceId: string = ""
  ParamsArray: string[] = []
  QRData: any;
  QR_info_data: any;
  orgLogo: string;
  redFlag: boolean = false;
  campaignName: string = "";
  isCampaign: boolean = false;
  private stripe: Stripe;
  stripeSetupData: any;
  private elements: StripeElements;
  private cardNumber: StripeCardNumberElement;
  private cardExpiry: StripeCardExpiryElement;
  private cardCvc: StripeCardCvcElement;
  stripeCardNumberErrorMessage;
  stripeExpiryErrorMessage;
  stripeCVCErrorMessage;
  sponsorName: string;
  accountId: string;
  paymentSourceData;
  cardValidatorsData: any;
  orgid: any;
  whitelabel: any;
  emailId: any;
  actionId: any;
  qrcode_id: any;
  isLoginClicked = false;
  loginForm: FormGroup;
  constructor(private giveNowService: GiveNowService, private activatedRoute: ActivatedRoute, private route: Router, private spinnerService: NgxSpinnerService, private toastMessage: ToastMessage) {

    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.fatchedParams = params
      this.orgid = params['organization'];
      //if (params?.sponsor) {
      //  this.sponsorName = params?.sponsor;
      //}
      this.whitelabel = params['whitelabel']
      if (params?.payeraccountid) {
        this.accountId = params?.payeraccountid;
      }
      if (params?.organizationid) {
        this.orgid = params?.organizationid;
      }
      this.emailId = params['emailid'];
      this.actionId = params['actionid'];
    })
    this.setCardControlsValidators();
  }
  ngAfterViewInit(): void {

  }
  ngOnDestroy(): void {

  }
  ngOnInit(): void {
    this.setRecurringFrequencyList();
    this.initializeStripe();
    this.loginForm = new FormGroup({
      username: new FormControl('', [Validators.required, this.noWhitespace]),
      password: new FormControl('', [
        Validators.required,
        this.noWhitespace
      ])
    })
    this.paymentForm = new FormGroup({
      account_id: new FormControl(""),
      transaction_type: new FormControl(),
      transaction_type_id: new FormControl(""),
      card: new FormGroup({
        email: new FormControl("", this.cardValidatorsData.email),
        name_on_card: new FormControl("", this.cardValidatorsData.name_on_card),
        zip: new FormControl("", this.cardValidatorsData.zip),
      }),
      //amount: new FormControl(null, [Validators.required, Validators.pattern("^[0-9]{0,3}\.[0-9]{0,2}$"), Validators.max(999.99), Validators.minLength(1), Validators.maxLength(6)]),
      amount: new FormControl(null, [Validators.required, Validators.min(1)]),
      tip_amount: new FormControl(0),
      no_share_giving: new FormControl(false),
      payer_cover_amount: new FormControl(),
      allow_payer_cover_amount: new FormControl(true),
      sponsor_username: new FormControl(this.sponsorName),
      tip_percent: new FormControl(15),
      existing_payment_source: new FormControl(false),
      payment_source_id: new FormControl(null),
      future_use: new FormControl(false),
      payer_account_id: new FormControl(this.accountId),
      tracking_code: new FormControl(""),
      key_values: new FormControl([]),
      recurring_frequency: new FormControl(null, [Validators.required]),
      create_account: new FormControl(false),
      email_optin: new FormControl(false),
    });
    this.checkAPI();

    //this.showStep(this.currentStep);
  }
  private async initializeStripe() {
    let publishableKey = environment.stripe.publicKey;
    this.stripe = await loadStripe(publishableKey);
    this.elements = this.stripe.elements();


  }
  setSponserName(sponserName: any) {
    this.paymentForm.patchValue({
      sponsor_username: sponserName
    })

  }

  setAccountId(data) {
    this.paymentForm.patchValue({
      account_id: data
    })
  }
  setTransactionType(data) {
    this.paymentForm.patchValue({
      transaction_type: Number(data)
    })
  }

  setTansactionTypeId(data) {
    this.paymentForm.patchValue({
      transaction_type_id: data
    })
  }
  loadStripeElement() {
    if (this.cardNumber) {
      this.cardNumber.unmount();
      this.cardExpiry.unmount();
      this.cardCvc.unmount();
    }
    this.mountCardNumberElement();
    this.mountCardExpiryElement();
    this.mountCardCVCElement();
  }

  private mountCardNumberElement() {
    const cardNumberOptions: StripeCardNumberElementOptions = {
      showIcon: true,
      classes: this.getCardOptions().classes,
      style: this.getCardOptions().style,
      iconStyle: 'solid',
    };

    // card number
    this.cardNumber = this.elements.create('cardNumber', cardNumberOptions);
    this.cardNumber.mount('#card-number-element');
    this.cardNumber.on('change', event => {
      if (event.complete) {
        this.stripeCardNumberErrorMessage = null;
      } else if (event.error) {
        this.stripeCardNumberErrorMessage = event.error.message;
      }
    });
  }

  mountCardExpiryElement() {
    const cardExpiryOptions: StripeCardExpiryElementOptions = {
      classes: this.getCardOptions().classes,
      style: this.getCardOptions().style,

    };
    this.cardExpiry = this.elements.create('cardExpiry', cardExpiryOptions);
    this.cardExpiry.mount('#card-exp-element');

    this.cardExpiry.on('change', event => {
      if (event.complete) {
        this.stripeExpiryErrorMessage = null;
      } else if (event.error) {
        this.stripeExpiryErrorMessage = event.error.message;
      }
    });
  }

  mountCardCVCElement() {
    const cardCVCOptions: StripeCardCvcElementOptions = {
      classes: this.getCardOptions().classes,
      style: this.getCardOptions().style,

    };
    this.cardCvc = this.elements.create('cardCvc', cardCVCOptions);
    this.cardCvc.mount('#card-cvc-element');

    this.cardCvc.on('change', event => {
      if (event.complete) {
        this.stripeCVCErrorMessage = null;
      } else if (event.error) {
        this.stripeCVCErrorMessage = event.error.message;
      }
    });
  }

  getCardOptions() {
    let cardOptions: StripeCardNumberElementOptions = {
      classes: {
        base: 'form-control',
        invalid: 'is-invalid',
        complete: 'is-valid',
        focus: "form-control stripeelementfocus",
        empty: "form-control"
      },
      style: {
        base: {
          //iconColor: '#007bff',
          //color: '#495057',
          lineHeight: '1.7',
          //fontWeight: 300,
          //fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          //fontSize: '16px',
          '::placeholder': {
            color: 'rgba(33, 37, 41, 0.75)'
          },

        },
        invalid: {
          iconColor: '#dc3545',
          color: '#dc3545'
        },
      }
    }
    return cardOptions;
  }

  checkAPI() {
    if (this.fatchedParams) {
      let textData = this.fatchedParams.data;
      let ArrayOfParams = textData.split(":");
      this.ParamsArray.push(ArrayOfParams);
      this.getQrCodeUrlData(this.ParamsArray[0][2]);
      this.setAccountId(this.ParamsArray[0][1]);
      this.setTransactionType(this.ParamsArray[0][0]);
      this.setTansactionTypeId(this.ParamsArray[0][2]);
      if (this.ParamsArray.length > 0 && this.ParamsArray[0].length > 4) {
        this.setSponserName(this.ParamsArray[0][4]);
      }
      //this.getTrackingData(this.ParamsArray[0][2])

    }
  }

  setCardControlsValidators() {
    this.cardValidatorsData = {
      email: [Validators.required, Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')],
      name_on_card: [Validators.required],
      zip: [Validators.pattern("^\\d{5}(-{0,1}\\d{4})?$"), Validators.minLength(5), Validators.maxLength(11)]
    };

  }
  getQrCodeUrlData(sourceId: string) {
    this.spinnerService.show();
    this.qrcode_id = sourceId;
    this.giveNowService.getQRCodeURLInfo(sourceId).subscribe(res => {
      this.QRData = res;
      this.QR_info_data = JSON.parse(this.QRData?.qr_info_data);
      this.orgNameAndLogo();

      this.getCampaignIntakePageSettings();

      if (this.QR_info_data?.intake_rules?.require_email) {
        //this.cardValidatorsData.email = [Validators.required, Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')];
        //this.paymentForm.get('card').get('email').setValidators(this.cardValidatorsData.email)
        //this.paymentForm.get('card').get('email').updateValueAndValidity();
      }
      if (this.QR_info_data?.campaign != null) {
        if (this.QR_info_data?.owner == this.QR_info_data?.campaign) {
          this.isCampaign = false;
        } else {
          this.isCampaign = true;
          this.campaignName = this.QR_info_data?.campaign;
        }
      } else {
        this.isCampaign = false;
      }
      this.getPaymentSources();
      //this.spinnerService.hide();
    })
  }
  getPaymentSources(isfromLoad = true) {

    if (this.accountId) {
      this.giveNowService.getWallet(this.accountId).subscribe(
        (res: any) => {
          // this.spinnerService.hide(this.paymentSourceSpinnerName);
          //this.spinnerService.hide();

          if (!res) {
            this.paymentForm.controls["payer_account_id"].setValue(null);
            return;

          }
          this.paymentSourceData = res;
          if (res && res.charge_cards && res.charge_cards.length > 0) {

            this.paymentSourceData.charge_cards = this.paymentSourceData?.charge_cards.filter(b => {
              return b.status_state?.toLocaleLowerCase() == 'ready'
            });

            if (this.paymentSourceData?.charge_cards && this.paymentSourceData?.charge_cards.length > 0) {
              this.paymentForm.controls["existing_payment_source"].setValue(true);
              this.selectExistingPaymentChange();
              return;
            }
          }

        },
        error => {
          // handle error
          this.paymentForm.controls["payer_account_id"].setValue(null);
          this.spinnerService.hide();
        },
        () => {
          if (isfromLoad) {
            this.getPayerCoverAmount();
          }

        }
      );
    }
    else {
      this.getPayerCoverAmount();
      // this.spinnerService.hide();
    }

  }
  selectExistingPaymentChange() {
    timer(200).subscribe(() => {
      this.paymentForm.controls["payment_source_id"].clearValidators();
      this.paymentForm.controls["payment_source_id"].reset();
      this.paymentForm.controls["payment_source_id"].setValue("");
      if (this.paymentForm.value.future_use) {
        this.paymentForm.controls["future_use"].setValue(false);
      }


      if (this.paymentForm.value.existing_payment_source) {
        this.paymentForm.controls["payment_source_id"].addValidators(Validators.required);
        //let cardForm = this.paymentForm['controls']['card'] as FormGroup;
        // cardForm.get('name_on_card').clearValidators();
        //cardForm.get('name_on_card').updateValueAndValidity();
        //cardForm.get('zip').clearValidators();
        //cardForm.get('zip').updateValueAndValidity();
        //cardForm.get('email').clearValidators();
        // cardForm.get('email').updateValueAndValidity();
      }
      else {
        this.paymentForm.controls["payment_source_id"].reset();

        this.resetCardControls();
        timer(500).subscribe(() => {
          this.elements = this.stripe.elements();
          this.loadStripeElement();
        });

      }
      this.paymentForm.controls['payment_source_id'].updateValueAndValidity();
    });


  }

  payerCoverAmountData: any;

  getPayerCoverAmount() {
    this.spinnerService.show();
    this.giveNowService.getPayerCoverAmount(this.QR_info_data.owner_id).subscribe(
      (res: any) => {
        this.payerCoverAmountData = res;
        this.spinnerService.hide();
      },
      error => {
        // handle error
        this.spinnerService.hide();
      },
      () => {
        //if (!this.payerCoverAmountData)
        //this.payerCoverAmountData = {
        //  "per_transaction_amount": 0.25,
        //  "per_transaction_percent": 5.0
        //}
      }
    );

  }

  resetCardControls() {
    this.stripeCardNumberErrorMessage = null;
    this.stripeExpiryErrorMessage = null;
    this.stripeCVCErrorMessage = null;
    let cardForm = this.paymentForm['controls']['card'] as FormGroup;
    //cardForm.markAsUntouched();
    //cardForm.reset();
    //cardForm.get('name_on_card').addValidators(this.cardValidatorsData.name_on_card);
    //cardForm.get('zip').addValidators(this.cardValidatorsData.zip);
    //if (this.QR_info_data?.intake_rules?.require_email && this.cardValidatorsData.email) {
    //  cardForm.get('email').setValidators(this.cardValidatorsData.email)
    //}
  }

  getTrackingData(campaignid: string) {
    this.giveNowService.getTrackingData(campaignid).subscribe(res => {
      //this.trackingDetails = res;
      //this.arraytracking = this.trackingDetails?.tracking_codes;
    })
  }

  updateActionTracking(emailId: string, actionId: string, accountId: any, postData: any) {
    //  const totalAmountWithTip = this.calculateTotalAmtForTip();
    // const totalAmountWithFees = this.calculateTotalAmt();
    const donationAmount = postData.amount
    const payload = {
      account_id: accountId,
      email_id: emailId,
      action_id: actionId,
      action_tracking: {
        action_id: actionId,
        action_name: "Give Now",
        action_page: true,
        action_amount: donationAmount,
        action_outcome: "",
        campaign_id: this.qrcode_id
      }
    };

    // if (this.QR_info_data?.intake_rules?.require_tips) {
    //   payload.action_tracking.action_amount = totalAmountWithTip
    // } else {
    //   payload.action_tracking.action_amount = totalAmountWithFees
    // }

    // Call your API service to update the API
    this.giveNowService.getActionTracking(emailId, payload).subscribe(
      (res: any) => {
        // console.log('API updated successfully:', res);
      },
      (error: any) => {
        console.error('Error updating API:', error);
      }
    );
  }

  orgNameAndLogo() {
    this.organizationName = this.QR_info_data?.owner
    this.orgLogo = this.QR_info_data?.small_logo_path
  }
  getPrimaryColor() {
    return this.campaignIntakePageSettingsData?.primary_color;
  }

  getFrencyLabelStyles(recurring) {
    return this.paymentForm.value.recurring_frequency == recurring
      ? {
        'border': `1px solid ${this.campaignIntakePageSettingsData?.primary_color}`,
        'background': `${this.campaignIntakePageSettingsData?.primary_color}`,
        'color': `#fff`
      }
      : { 'border': `1px solid #2b2b2b` }

  }
  getCheckboxStyles(controlName) {
    return this.paymentForm.value[controlName]
      ? {
        'background-color': `${this.campaignIntakePageSettingsData?.primary_color}`,
        'border-color': `${this.campaignIntakePageSettingsData?.primary_color}`
      }
      : { 'border-color': `${this.campaignIntakePageSettingsData?.primary_color}` }

  }
  getPaymentSourceRadioButonStyles(controlName, forname) {
    return (this.paymentForm.value[controlName] && forname == 'existingPayment') ||
      (!this.paymentForm.value[controlName] && forname == 'newPayment')
      ? {
        'background': `${this.campaignIntakePageSettingsData?.primary_color}`,
        'color': `white`,
        'padding': `10px 15px`,
        'border-radius': `30px`,
        'text-shadow': `0px 1px 4px #767676`,
      }
      : {}

  }
  getButtonStyles() {
    return {
      'background-color': `${this.campaignIntakePageSettingsData?.button_color}`,
      'color': `${this.campaignIntakePageSettingsData?.button_text_color}`
    }
  }
  recurringFrequencyList = {};
  setRecurringFrequencyList() {
    this.recurringFrequencyList = {
      'onetime': 'One-Time',
      'daily': 'Daily',
      'weekly': 'Weekly',
      'bi-weekly': 'Bi-Weekly',
      '1st and 16th': '1st and 16th',
      'monthly': 'Monthly',
      'quarterly': 'Quarterly',
      'annually': 'Annually'
    }
  }

  validateAmountStep() {
    if (this.paymentForm.controls["recurring_frequency"].valid &&
      this.paymentForm.controls["amount"].valid
    ) {
      this.changeStep(1);
    }
    else {
      this.paymentForm.controls["recurring_frequency"].markAsTouched();
      this.paymentForm.controls["amount"].markAsTouched();
    }
  }
  validateInformationStep() {
    let cardControls = this.paymentForm['controls']['card']['controls'];
    if (cardControls["name_on_card"].valid &&
      cardControls["email"].valid &&
      cardControls["zip"].valid
    ) {
      this.changeStep(2);
     // this.paymentForm.controls["future_use"].setValue
      let existing_payment_source = this.paymentForm.controls["existing_payment_source"].value
      if (!existing_payment_source) {
        this.elements = this.stripe.elements();
        this.loadStripeElement();
      }
    }
    else {
      cardControls["name_on_card"].markAsTouched();
      cardControls["email"].markAsTouched();
      cardControls["zip"].markAsTouched();

    }
  }
  currentStep = 0;

  showStep(step) {
    let steps = document.querySelectorAll('.form-step');
    steps.forEach((s: any, index) => s.style.display = index === step ? 'block' : 'none');
    this.updateProgressBar();
  }

  changeStep(delta) {
    let steps = document.querySelectorAll('.form-step');
    this.currentStep = Math.max(0, Math.min(this.currentStep + delta, steps.length - 1));
    this.showStep(this.currentStep);
  }
  updateProgressBar() {
    let steps = document.querySelectorAll('.form-step');
    const progressBar = document.getElementById('progressBar');
    const percentage = ((this.currentStep + 1) / steps.length) * 100;
    progressBar.style.width = percentage + '%';
  }

  setAmount(amount) {
    this.paymentForm.controls['amount'].setValue(amount);
  }

  getPredifinedAmountStyles(ampunt) {
    return this.paymentForm.value.amount == ampunt
      ? {
        'background-color': ` ${this.campaignIntakePageSettingsData?.primary_color}`,
        'border-color': `${this.campaignIntakePageSettingsData?.primary_color}`,
        'color': `#fff`
      }
      : { 'border-color': `#2b2b2b`, 'color': `#2b2b2b` }

  }
  getHeaderStyles() {

    return {
      'background': ` ${this.campaignIntakePageSettingsData?.primary_color}`,
    }

  }

  getCampaignIntakePageSettings() {
    //this.spinnerService.show();
    this.giveNowService.getCampaignIntakePageSettings(this.qrcode_id).subscribe((res: any) => {
      if (res) {
        this.campaignIntakePageSettingsData = res;

        if (this.campaignIntakePageSettingsData?.recurring_frequencies?.length == 1 && this.campaignIntakePageSettingsData?.recurring_frequencies[0] == "onetime") {
          this.campaignIntakePageSettingsData.recurring_frequencies = [];
        }
        if (!this.campaignIntakePageSettingsData?.default_frequency?.trim()) {
          this.campaignIntakePageSettingsData.default_frequency = "onetime";
        }
        this.paymentForm.controls["recurring_frequency"].setValue(this.campaignIntakePageSettingsData?.default_frequency)

        if (this.campaignIntakePageSettingsData?.minimum_donation_amount && this.campaignIntakePageSettingsData?.minimum_donation_amount > 1) {
          this.paymentForm.controls["amount"].clearValidators();
          this.paymentForm.controls["amount"].addValidators([Validators.required, Validators.min(this.campaignIntakePageSettingsData?.minimum_donation_amount)])
          this.paymentForm.controls["amount"].updateValueAndValidity();
        }
        //campaignIntakePageSettingsData?.allow_tracking_code
       // this.trackingDetails = this.campaignIntakePageSettingsData.tracking_code;
        //this.arraytracking = this.trackingDetails?.code_list;
        //this.showStep(this.currentStep);
        // this.spinnerService.hide();
      }
      else {
        //this.router.navigate(["/event"]);
        // this.spinnerService.hide();
        ;
      }

    },
      error => {
        // handle error
        //this.spinnerService.hide();

      },
      () => {
        // finally -- exiting from method
      }
    );
  }
  get mainFormControls() {
    return this.paymentForm['controls']
  }

  get cardFormControls() {
    return this.paymentForm['controls']['card']['controls']
  }

  giftFeesAmt() {
    let total = 0.00;
    if (this.payerCoverAmountData?.per_transaction_percent > 0) {
      total = parseFloat(this.paymentForm.value.amount) * this.payerCoverAmountData?.per_transaction_percent / 100
    }
    if (this.payerCoverAmountData?.per_transaction_amount > 0) {
      if (total == 0) {
        total = parseFloat(this.paymentForm.value.amount);
      }
      total = total + this.payerCoverAmountData?.per_transaction_amount
    }
    return total;
  }
  calculateGiftFees() {

    if (this.campaignIntakePageSettingsData?.payer_cover_fee && this.paymentForm.controls['allow_payer_cover_amount'].value) {
      return this.giftFeesAmt();
    }
    else {
      return 0;
    }

  }
  calculateTotalAmt() {
    let total = Number(this.paymentForm.value.amount) + this.calculateGiftFees()
    return total
  }
  openLoginForm() {
    this.isLoginClicked = true;
  }

  noWhitespace(control: FormControl) {
    if (control.value) {
      let isWhitespace = (control.value as string).indexOf(' ') >= 0;
      let isValid = !isWhitespace;
      return isValid ? null : { 'whitespace': true }
    }
    return null;
  }

  get loginFormControls() {
    return this.loginForm['controls'];
  }

  onLogin() {
    if (this.loginForm.valid) {
      this.spinnerService.show();
      let request = this.loginForm.value
      this.giveNowService.signIn(request).subscribe(
        (res: any) => {
          if (res && res.account_id) {
            this.paymentForm.controls["payer_account_id"].setValue(res.account_id);
            this.isLoginClicked = false;
            this.accountId = res.account_id
            this.getPaymentSources(false);
            let params = { ...this.fatchedParams }

            params.payeraccountid = this.accountId;
            this.loginForm.reset();
            this.route.navigate(
              [],
              {
                relativeTo: this.activatedRoute,
                queryParams: params,
                queryParamsHandling: 'merge', // remove to replace all query params by provided
              });
          }

          this.spinnerService.hide();
        },
        error => {
          // handle error
          this.spinnerService.hide();
          this.toastMessage.Error(error?.error?.Message)

        },
        () => {

        }
      );
    }
    else {
      for (let inner in this.loginForm.controls) {
        this.loginForm.get(inner).markAsTouched();
        this.loginForm.get(inner).markAsDirty();
      }
    }


  }
  onLogout() {
    this.isLoginClicked = false;
    this.accountId = null;
    this.paymentForm.controls["payer_account_id"].setValue(null);
    this.paymentSourceData = null;
    this.paymentForm.controls["create_account"].setValue(false);
    this.paymentForm.controls["future_use"].setValue(false);
    let existing_payment_source = this.paymentForm.controls["existing_payment_source"].value
    this.paymentForm.controls["existing_payment_source"].setValue(false);
    let steps = document.querySelectorAll('.form-step');
    if (this.currentStep == steps.length - 1) {
      if (existing_payment_source) {
        this.selectExistingPaymentChange();
      }
    }
    let params = { ...this.fatchedParams }
    delete params["payeraccountid"];
    this.loginForm.reset();
    this.route.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: params
        // remove to replace all query params by provided
      });


  }
  onLoginCancel() {
    this.isLoginClicked = false;
  }

  onSubmit() {

    if (this.paymentForm.valid) {
      if (this.paymentForm.controls['allow_payer_cover_amount'].value) {
        this.paymentForm.controls['payer_cover_amount'].setValue(Number(this.calculateGiftFees().toFixed(2)))
        this.paymentForm.controls['tip_percent'].setValue(0.00)
      } else {
        this.paymentForm.controls['payer_cover_amount'].setValue(0.00)
        this.paymentForm.controls['tip_percent'].setValue(0.00)
      }

      if (this.paymentForm.value.tracking_code != "Select Code" && this.paymentForm.value.tracking_code != "") {
        // let selectedValue = this.arraytracking.find(x => x.code === this.paymentForm.value.tracking_code);
        this.paymentForm.patchValue({
          tracking_code: this.paymentForm.value.tracking_code,
          key_values: [{ trackingCode: this.campaignIntakePageSettingsData.tracking_code.label?.trim(), value: this.paymentForm.value.tracking_code }]
        })
      }
      this.paymentForm.patchValue({
        amount: Number(this.paymentForm.value.amount),
        tip_amount: Number(this.calculatedTipAmount?.toFixed(2))
      })
      if (this.paymentForm.value.recurring_frequency != 'onetime') {
        if (!this.paymentForm.value.existing_payment_source && !this.paymentForm.value.payer_account_id) {
          this.paymentForm.controls["create_account"].setValue(true);
          this.paymentForm.controls['future_use'].setValue(true);
        }
        else if (!this.paymentForm.value.existing_payment_source && this.paymentForm.value.payer_account_id) {
          this.paymentForm.controls["create_account"].setValue(false);
          this.paymentForm.controls['future_use'].setValue(true);
        }
        else {
          this.paymentForm.controls["create_account"].setValue(false);
          this.paymentForm.controls['future_use'].setValue(false);
        }
      }
      else if (this.paymentForm.value.payer_account_id) {
        this.paymentForm.controls["create_account"].setValue(false);
        if (this.paymentForm.value.existing_payment_source) {
          this.paymentForm.controls['future_use'].setValue(false);
        }
      }      
      
      let postData: any = { ...this.paymentForm.value };

      if (postData.existing_payment_source) {
        postData.payment_source_type = "charge_card";
        postData.card = null;
        postData.payer_name = this.paymentForm.value.card.name_on_card;
        postData.payer_email = this.paymentForm.value.card.email;
        postData.payer_zip = this.paymentForm.value.card.zip;
        //postData.recurring_frequency = null;
        //delete postData["recurring_frequency"];
        // delete postData["create_account"];
        // delete postData["email_optin"];
        this.spinnerService.show();
        this.postGiveNow(postData);
      }
      else {
        this.createPaymentMethodAndPostData();
      }
    }
    else {

      for (let inner in this.paymentForm.controls) {
        this.paymentForm.get(inner).markAsTouched();
        this.paymentForm.get(inner).markAsDirty();
      }
      return;

    }
  }

  createPaymentMethodAndPostData() {
    this.spinnerService.show();
    const cardHolderName = this.paymentForm.value.card.name_on_card;
    let paymentMethodParams: any = {
      type: 'card',
      card: this.cardNumber,
      billing_details: {
        name: cardHolderName,
        email: this.paymentForm.value.card.email,
      }
    }

    if (this.paymentForm.value.card.zip && this.paymentForm.value.card.zip.trim()) {
      paymentMethodParams.billing_details.address = {
        postal_code: this.paymentForm.value.card.zip.trim()
      }
    }
    if (this.paymentForm.value.card.email && this.paymentForm.value.card.email.trim()) {
      paymentMethodParams.billing_details.email = this.paymentForm.value.card.email.trim()
    }
    this.stripe.createPaymentMethod(paymentMethodParams)
      .then((result) => {
        if (result.error) {
          this.spinnerService.hide();
          this.toastMessage.Error(result.error.message, 3000);
          return;
        }
        let postData: any = { ...this.paymentForm.value };
        postData.payment_source_id = result.paymentMethod.id;
        postData.payment_source_type = "charge_card";

        postData.payer_name = this.paymentForm.value.card.name_on_card;
        postData.payer_email = this.paymentForm.value.card.email;
        postData.payer_zip = this.paymentForm.value.card.zip;
        postData.card = null;
        //postData.recurring_frequency = null;
        //delete postData["recurring_frequency"];
        // delete postData["create_account"];
        //delete postData["email_optin"];
        //postData.paymentIntentId = paymentIntentId;
        this.postGiveNow(postData);


      }).catch(error => {
        this.spinnerService.hide();

      });
  }

  postGiveNow(postData: any) {
    let accountid = postData.account_id
    let campaignid = postData.transaction_type_id
    if (this.emailId && this.actionId) {
      this.updateActionTracking(this.emailId, this.actionId, accountid, postData);
    }
    this.giveNowService.postPaymentDetails(postData).subscribe(res => {
      if (res.status == "succeeded") {
        this.spinnerService.hide();
        this.route.navigate(['paysuccess', this.QR_info_data?.owner_id]);
      }
      else if (res.status == "requires_action") {
        this.stripe.handleCardAction(res.details.payment_intent_client_secret).then((result) => {
          if (result.error) {
            this.toastMessage.Error(result.error.message);
            // redirect to error payment failed
            this.spinnerService.hide();
          } else {
            // call the server;
            postData.payment_intent_id = res.details.payment_intent_id;
            this.postGiveNow(postData);
          }

        })
      }
      else {
        this.spinnerService.hide();
        this.redirecToFailed();
      }
    },
      error => {
        this.spinnerService.hide();
        this.redirecToFailed();

      });
  }

  redirecToFailed() {
    this.route.navigate(['payfailed', this.QR_info_data?.owner_id]);
  }
  showFutureUseCheckbox() {
    if (!this.paymentForm.value.existing_payment_source) {
      if (this.paymentForm.value.payer_account_id && this.paymentForm.value.recurring_frequency == 'onetime') {
        return true;
      }
      else if (!this.paymentForm.value.payer_account_id && this.paymentForm.value.create_account && this.paymentForm.value.recurring_frequency == 'onetime') {
        return true;
      }

    }
    return false;
  }
}
