import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Stripe, StripeCardCvcElement, StripeCardCvcElementOptions, StripeCardExpiryElement, StripeCardExpiryElementOptions, StripeCardNumberElement, StripeCardNumberElementOptions, StripeElements, loadStripe } from '@stripe/stripe-js';
import { NgxSpinnerService } from 'ngx-spinner';
import { timer } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ToastMessage } from '../../../common/commonfunction';
import { GiveNowService } from '../../../_services/give-now.service';

@Component({
  selector: 'app-pay-now-openauction',
  templateUrl: './pay-now-openauction.component.html',
  styleUrls: ['./pay-now-openauction.component.css']
})
export class PayNowOpenauctionComponent {
  auctionPaymentForm: FormGroup;
  submitted: boolean = false;
  years: any = [];
  months: any = [];
  currentMonth: any = [];
  currentYear: any = [];
  thisMonth: number
  thisYear: number
  rangeValue: number
  calculatedTipAmount: number = 0;
  donationAmount: number;
  fatchedParams: any;
  organizationName: string;
  sourceId: string = ""
  ParamsArray: string[] = []
  QRData: any;
  QR_info_data: any;
  orgLogo: string;
  redFlag: boolean = false;
  campaignName: string = "";
  isCampaign: boolean = false;
  private stripe: Stripe;
  stripeSetupData: any;
  private elements: StripeElements;
  private cardNumber: StripeCardNumberElement;
  private cardExpiry: StripeCardExpiryElement;
  private cardCvc: StripeCardCvcElement;
  stripeCardNumberErrorMessage;
  stripeExpiryErrorMessage;
  stripeCVCErrorMessage;
  sponsorName: string;
  //accountId: string;
  paymentSourceData;
  cardValidatorsData: any;
  orgid: any;
  whitelabel: any;
  isTrackingCode: boolean = false;
  trackingDetails: any;
  address: any;
  shipping_amount: any;
  tax_amount: any;
  owner: any;
  status_state: any;
  order_id: any;
  phone: any;
  small_logo_path: any;
  arraytracking: any = ["test", "test02", "test03", "test04", "test05", "test06", "test07"];
  type: any;
  auctionid: any;
  account_Id: any;
  organization_id: any;
  orderDetails: any;
  itemId: any;
  emailId: any;
  actionId: any;
  qrcode_Id: any;

  constructor(private giveNowService: GiveNowService, private activatedRoute: ActivatedRoute, private route: Router,
    private spinnerService: NgxSpinnerService, private toastMessage: ToastMessage) {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.fatchedParams = params
      this.type = params['type'];
      if (params?.sponsor) {
        this.sponsorName = params?.sponsor;
      }
      this.auctionid = params['auctionid']
      this.itemId = params['itemid']
      //if (params?.payeraccountid) {
      //  this.accountId = params?.payeraccountid;
      //}
      this.organization_id = params?.organizationid?.trim()
      this.emailId = params['emailid'];
      this.actionId = params['actionid'];
      this.qrcode_Id = params['qrcode_id'];
    })
    this.setCardControlsValidators();
  }

  setCardControlsValidators() {
    this.cardValidatorsData = {
      name_on_card: [Validators.required],
      zip: [Validators.pattern("^\\d{5}(-{0,1}\\d{4})?$"), Validators.minLength(5), Validators.maxLength(11)]
    };

  }

  ngOnInit() {
    if (!this.organization_id) {
      history.back();
      return;
    }
    this.initializeStripe();
    this.auctionBidOrderData();
    this.auctionPaymentForm = new FormGroup({
      account_id: new FormControl(""),
      transaction_type: new FormControl(5002),
      transaction_type_id: new FormControl(this.itemId),
      card: new FormGroup({
        email: new FormControl(""),
        name_on_card: new FormControl("", this.cardValidatorsData.name_on_card),
        zip: new FormControl("", this.cardValidatorsData.zip),
      }),
      amount: new FormControl([Validators.required, Validators.min(1)]),
      tip_amount: new FormControl(0),
      no_share_giving: new FormControl(false),
      allow_payer_cover_amount: new FormControl(true),
      payer_cover_amount: new FormControl(0),
      sponsor_username: new FormControl(this.sponsorName),
      existing_payment_source: new FormControl(false),
      payment_source_id: new FormControl(""),
      future_use: new FormControl(false),
      payer_account_id: new FormControl(""),
      key_values: new FormControl([]),
      qrcode_type: new FormControl("auction"),
      qrcode_id: new FormControl(this.itemId)
    });


  }

  ngOnDestroy(): void {
    this.spinnerService.hide();
  }

  get f() {
    return this.auctionPaymentForm['controls']['card']['controls']
  }
  get a() {
    return this.auctionPaymentForm['controls']
  }


  private async initializeStripe() {
    let publishableKey = environment.stripe.publicKey;
    this.stripe = await loadStripe(publishableKey);
    this.elements = this.stripe.elements();
    this.loadStripeElement();


  }

  auctionBidOrderData() {
    this.spinnerService.show();
    this.giveNowService.getopenauctionOrderData(this.organization_id, this.auctionid, this.itemId).subscribe(res => {
      if (res) {
        this.orderDetails = res.bids.filter((x: any)=> x.bid_price == res.highest_bid_amount);
        if (res?.order?.payer_account_id && res?.order?.payer_account_id.trim()) {
          this.getPaymentSources(res?.order?.payer_account_id);
        }
        else {
          //this.spinnerService.hide();
          this.getPayerCoverAmount();
        }
        this.auctionPaymentForm.patchValue({
          card: { email: this.orderDetails[0]?.contact_info?.email },
          amount: this.orderDetails[0]?.bid_price
        })
        //this.orderId = this.orderDetails[0]?.auction_item_id;
        this.status_state = this.orderDetails[0]?.status_state;
      }
    },
      error => {
        this.spinnerService.hide();
      })
  }
  getPaymentSources(accountId: any) {


    this.giveNowService.getWallet(accountId).subscribe(
      (res: any) => {
        this.spinnerService.hide();
        if (!res) {
          this.auctionPaymentForm.controls["payer_account_id"].setValue(null);
          return;

        }
        this.auctionPaymentForm.controls["payer_account_id"].setValue(accountId);
        this.paymentSourceData = res;
        if (res && res.charge_cards && res.charge_cards.length > 0) {

          this.paymentSourceData.charge_cards = this.paymentSourceData?.charge_cards.filter(b => {
            return b.status_state?.toLocaleLowerCase() == 'ready'
          });

          if (this.paymentSourceData?.charge_cards && this.paymentSourceData?.charge_cards.length > 0) {
            this.auctionPaymentForm.controls["existing_payment_source"].setValue(true);
            this.selectExistingPaymentChange();
            return;
          }
        }

      },
      error => {
        // handle error
        this.auctionPaymentForm.controls["payer_account_id"].setValue(null);
        this.spinnerService.hide();
      },
      () => {
        this.getPayerCoverAmount();
      }
    );



  }

  onSubmit() {
    this.submitted = true;
    if (this.auctionPaymentForm.controls['allow_payer_cover_amount'].value) {
      this.auctionPaymentForm.controls['payer_cover_amount'].setValue(this.calculateGiftFees()?.toFixed(2))
    } else {
      this.auctionPaymentForm.controls['payer_cover_amount'].setValue(0.00)
    }

    if (this.auctionPaymentForm.value.tracking_code != "Select Code" && this.auctionPaymentForm.value.tracking_code != "") {
      let selectedValue = this.arraytracking.find(x => x.code === this.auctionPaymentForm.value.tracking_code);
      this.auctionPaymentForm.patchValue({
        tracking_code: this.auctionPaymentForm.value.tracking_code,
        key_values: [{ trackingCode: selectedValue.label, value: selectedValue.code }]
      })
    }
    this.auctionPaymentForm.patchValue({
      amount: Number(this.auctionPaymentForm.value.amount),
      account_id: this.organization_id
      // tip_amount: Number(this.calculatedTipAmount?.toFixed(2))
    })
    if (this.auctionPaymentForm.invalid) {
      for (let inner in this.auctionPaymentForm.controls) {
        this.auctionPaymentForm.get(inner).markAsTouched();
        this.auctionPaymentForm.get(inner).markAsDirty();
      }
      return;
    }
    let postData: any = { ...this.auctionPaymentForm.value };
    if (postData.existing_payment_source) {
      postData.payment_source_type = "charge_card";
      postData.card = null;
      this.spinnerService.show();
      this.postGiveNow(postData);
    }
    else {
      this.createPaymentMethodAndPostData();
    }



  }



  calculateTotalAmt() {
    let total = Number(this.auctionPaymentForm.value.amount) + this.calculateGiftFees()
    return total
  }


  giftFeesAmt() {
    //let total = (Number(this.auctionPaymentForm.value.amount) * 0.05) + (this.auctionPaymentForm.value.amount > 0 ? 0.25 : 0.00)
    let total = 0.00
    if (this.payerCoverAmountData?.per_transaction_percent > 0) {
      total = parseFloat(this.auctionPaymentForm.value.amount) * this.payerCoverAmountData?.per_transaction_percent / 100
    }
    if (this.payerCoverAmountData?.per_transaction_amount > 0) {
      if (total == 0) {
        total = parseFloat(this.auctionPaymentForm.value.amount);
      }
      total = total + this.payerCoverAmountData?.per_transaction_amount
    }
    return total


  }

  calculateGiftFees() {
    if (this.auctionPaymentForm.controls['allow_payer_cover_amount'].value) {
      //let total = (Number(this.auctionPaymentForm.value.amount) * 0.05) + (this.auctionPaymentForm.value.amount > 0 ? 0.25 : 0.00)
      //return total
      return this.giftFeesAmt();
    }
    else {
      //let total = (Number(this.auctionPaymentForm.value.amount) * 0.00) + (this.auctionPaymentForm.value.amount > 0 ? 0.00 : 0.00)
      return 0;
    }
  }

  postGiveNow(postData: any) {
    this.giveNowService.postPaymentDetails(postData).subscribe(res => {
      if (res.status == "succeeded") {
        // this.spinnerService.hide();
        // this.route.navigate(['/event/order', this.orderId], { state: { Url: res?.details?.receipt_url } })
        if(this.emailId && this.actionId){
          this.updateActionTrackingForAuction(this.emailId, this.actionId, postData);
        }
        this.redirectToWhiteLableOrder(this.auctionid);
      }
      else if (res.status == "requires_action") {
        this.stripe.handleCardAction(res.details.payment_intent_client_secret).then((result) => {
          if (result.error) {
            this.toastMessage.Error(result.error.message);
            // redirect to error payment failed
            this.spinnerService.hide();
          } else {
            // call the server;
            postData.payment_intent_id = res.details.payment_intent_id;
            //postData.payment_intent_id = result.paymentIntent.id;
            this.postGiveNow(postData);
          }

        })
      }
      else {
        this.spinnerService.hide();
        this.route.navigate(['payfailed']);
      }
    },
      error => {
        this.route.navigate(['payfailed']);
        this.spinnerService.hide();

      });
  }

  createPaymentMethodAndPostData() {
    this.spinnerService.show();
    const cardHolderName = this.auctionPaymentForm.value.card.name_on_card;
    let paymentMethodParams: any = {
      type: 'card',
      card: this.cardNumber,
      billing_details: {
        name: cardHolderName,
        email: this.auctionPaymentForm.value.card.email,
      }
    }

    if (this.auctionPaymentForm.value.card.zip && this.auctionPaymentForm.value.card.zip.trim()) {
      paymentMethodParams.billing_details.address = {
        postal_code: this.auctionPaymentForm.value.card.zip.trim()
      }
    }
    if (this.auctionPaymentForm.value.card.email && this.auctionPaymentForm.value.card.email.trim()) {
      paymentMethodParams.billing_details.email = this.auctionPaymentForm.value.card.email.trim()
    }
    this.stripe.createPaymentMethod(paymentMethodParams)
      .then((result) => {
        if (result.error) {
          this.spinnerService.hide();
          this.toastMessage.Error(result.error.message, 3000);
          return;
        }
        let postData: any = { ...this.auctionPaymentForm.value };
        postData.payment_source_id = result.paymentMethod.id;
        postData.payment_source_type = "charge_card";

        postData.payer_name = this.auctionPaymentForm.value.card.name_on_card;
        postData.payer_email = this.auctionPaymentForm.value.card.email;
        postData.payer_zip = this.auctionPaymentForm.value.card.zip;
        postData.card = null;
        //postData.qrcode_type = "ticket";
        //postData.qrcode_id = this.orderId;
        this.postGiveNow(postData);

      }).catch(error => {
        this.spinnerService.hide();

      });
  }

  selectExistingPaymentChange() {
    timer(200).subscribe(() => {
      this.auctionPaymentForm.controls["payment_source_id"].clearValidators();
      this.auctionPaymentForm.controls["payment_source_id"].reset();
      this.auctionPaymentForm.controls["payment_source_id"].setValue("");
      if (this.auctionPaymentForm.value.future_use) {
        this.auctionPaymentForm.controls["future_use"].setValue(false);
      }

      if (this.auctionPaymentForm.value.existing_payment_source) {
        this.auctionPaymentForm.controls["payment_source_id"].addValidators(Validators.required);
        let cardForm = this.auctionPaymentForm['controls']['card'] as FormGroup;
        cardForm.get('name_on_card').clearValidators();
        cardForm.get('name_on_card').updateValueAndValidity();
        cardForm.get('zip').clearValidators();
        cardForm.get('zip').updateValueAndValidity();
        cardForm.get('email').clearValidators();
        cardForm.get('email').updateValueAndValidity();
      }
      else {
        //this.auctionPaymentForm.controls["payment_source_id"].reset();


        this.resetCardControls();
        timer(500).subscribe(() => {
          this.elements = this.stripe.elements();
          this.loadStripeElement();
        });

      }
      this.auctionPaymentForm.controls['payment_source_id'].updateValueAndValidity();
    });


  }


  resetCardControls() {
    this.stripeCardNumberErrorMessage = null;
    this.stripeExpiryErrorMessage = null;
    this.stripeCVCErrorMessage = null;
    let cardForm = this.auctionPaymentForm['controls']['card'] as FormGroup;
    cardForm.markAsUntouched();
    cardForm.reset();
    cardForm.get('name_on_card').addValidators(this.cardValidatorsData.name_on_card);
    cardForm.get('zip').addValidators(this.cardValidatorsData.zip);
    if (this.QR_info_data?.intake_rules?.require_email && this.cardValidatorsData.email) {
      cardForm.get('email').setValidators(this.cardValidatorsData.email)
    }
  }

  loadStripeElement() {
    if (this.cardNumber) {
      this.cardNumber.unmount();
      this.cardExpiry.unmount();
      this.cardCvc.unmount();
    }
    this.mountCardNumberElement();
    this.mountCardExpiryElement();
    this.mountCardCVCElement();
  }

  private mountCardNumberElement() {
    const cardNumberOptions: StripeCardNumberElementOptions = {
      showIcon: true,
      classes: this.getCardOptions().classes,
      style: this.getCardOptions().style,
      iconStyle: 'solid',
    };

    // card number
    this.cardNumber = this.elements.create('cardNumber', cardNumberOptions);
    this.cardNumber.mount('#card-number-element');
    this.cardNumber.on('change', event => {
      if (event.complete) {
        this.stripeCardNumberErrorMessage = null;
      } else if (event.error) {
        this.stripeCardNumberErrorMessage = event.error.message;
      }
    });
  }

  mountCardExpiryElement() {
    const cardExpiryOptions: StripeCardExpiryElementOptions = {
      classes: this.getCardOptions().classes,
      style: this.getCardOptions().style,

    };
    this.cardExpiry = this.elements.create('cardExpiry', cardExpiryOptions);
    this.cardExpiry.mount('#card-exp-element');

    this.cardExpiry.on('change', event => {
      if (event.complete) {
        this.stripeExpiryErrorMessage = null;
      } else if (event.error) {
        this.stripeExpiryErrorMessage = event.error.message;
      }
    });
  }

  mountCardCVCElement() {
    const cardCVCOptions: StripeCardCvcElementOptions = {
      classes: this.getCardOptions().classes,
      style: this.getCardOptions().style,

    };
    this.cardCvc = this.elements.create('cardCvc', cardCVCOptions);
    this.cardCvc.mount('#card-cvc-element');

    this.cardCvc.on('change', event => {
      if (event.complete) {
        this.stripeCVCErrorMessage = null;
      } else if (event.error) {
        this.stripeCVCErrorMessage = event.error.message;
      }
    });
  }

  getCardOptions() {
    let cardOptions: StripeCardNumberElementOptions = {
      classes: {
        base: 'form-control',
        invalid: 'is-invalid',
        complete: 'is-valid',
        focus: "form-control stripeelementfocus",
        empty: "form-control"
      },
      style: {
        base: {
          //iconColor: '#007bff',
          //color: '#495057',
          lineHeight: '1.7',
          //fontWeight: 300,
          //fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          //fontSize: '16px',
          '::placeholder': {
            color: 'rgba(33, 37, 41, 0.75)'
          },

        },
        invalid: {
          iconColor: '#dc3545',
          color: '#dc3545'
        },
      }
    }
    return cardOptions;
  }

  redirectToWhiteLableOrder(auctionid: any) {
    let redirectURL = environment.whitelable_domain_url
    redirectURL += "/auction/openauctionconfirmation?auctionid=" + this.auctionid;
    redirectURL +=  "&itemid=" + this.itemId;
    redirectURL += "&organizationid=" + this.organization_id;
    // let redirectURL = URL + `/openauctionconfirmation/confirm/order/${orderId}?organizationid=${this.organization_id}`;
    if (this.emailId && this.actionId) {
      redirectURL += "&emailid=" + this.emailId + "&actionid=" + this.actionId + "&qrcode_id=" + this.qrcode_Id;
    }
    window.location.href = redirectURL;
  }

  updateActionTrackingForAuction(emailId: string, actionId: string, postData: any){
    const donationAmount = postData.amount
    const payload = {
      account_id: this.organization_id,
      email_id: emailId,
      action_id: actionId,
      action_tracking: {
        action_id: actionId,
        action_name: "Give Now",
        action_page: true,
        action_amount: donationAmount,
        action_outcome:"",
        campaign_id: this.qrcode_Id,
      }
    };

    // Call your API service to update the API
    this.giveNowService.getActionTracking(emailId, payload).subscribe(
      (res: any) => {
        // console.log('API updated successfully:', res);
      },
      (error: any) => {
        console.error('Error updating API:', error);
      }
    );
  }

  payerCoverAmountData: any;

  getPayerCoverAmount() {
    this.spinnerService.show();
    this.giveNowService.getPayerCoverAmount(this.organization_id).subscribe(
      (res: any) => {
        this.payerCoverAmountData = res;
        this.spinnerService.hide();
      },
      error => {
        // handle error
        this.spinnerService.hide();
      },
      () => {
        //if (!this.payerCoverAmountData)
        //  this.payerCoverAmountData = {
        //    "per_transaction_amount": 0.25,
        //    "per_transaction_percent": 5.0
        //  }
      }
    );
  }
}
