<div class="container payment-failure-page">
  <div class="payment">
      <div class="payment_header">
         <div class="check">
            <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
         </div>
      </div>
      <div class="content pb-4 p-1 failedContent">
        <h2 class="failedTitle">Payment Failed</h2>
        <h1>Oops ! Something Went Wrong</h1>
        <h3>While trying to reserve money from your account</h3>
        <a  *ngIf="showCampaignButton" [href]="whitelableURL" class="btn d-inline-block mt-3 mb-4 lastPageBack_camp btn-light">Back to Campaigns</a>
      </div>
   </div>
</div>
